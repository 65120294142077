import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Blank from '@/views/layout/Blank.vue'
import Main from '@/views/layout/Main.vue'
import OrderDetail from "@/views/order/OrderDetail.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/auth'
  },
  {
    path: '/auth/',
    component: Blank,
    children: [
      {
        path: '',
        redirect: '/auth/login'
      },
      {
        name: 'login',
        path: 'login',
        component: () => import('@/views/auth/Login.vue')
      },
      {
        name: 'reset-password',
        path: 'reset-password/:hash',
        component: () => import('@/components/partial/ResetPassword.vue')
      },
      {
        path: 'forgotpw',
        component: () => import('@/views/auth/ForgotPw.vue')
      },
      {
        path: 'register',
        component: () => import('@/views/auth/Register.vue')
      },
      {
        path: 'otp',
        component: () => import('@/views/auth/Otp.vue')
      }
    ]
  },
  {
    path: '/main/',
    component: Main,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        redirect: '/main/home'
      },
      {
        path: 'home',
        component: () => import('@/views/main/Home.vue')
      },
      {
        path: 'order',
        component: () => import('@/views/main/Order.vue')
      },
      {
        path: 'campaign',
        component: () => import('@/views/main/Campaign.vue')
      },
      {
        path: 'profile',
        component: () => import('@/views/main/Profile.vue')
      }
    ]
  },
  {
    path: '/order/',
    component: Blank,
    children: [
      {
        path: 'checkout/:id',
        component: () => import('@/views/order/OrderDetail.vue')
      },
      {
        path: 'list-menu',
        component: () => import('@/views/order/PkgDetail.vue')
      },
      {
        path: 'orderdetail',
        component: () => import('@/views/order/OrderDetail.vue')
      },
      {
        path: 'checkout/:id/process',
        component: () => import('@/views/order/Checkout.vue')
      },
      {
        path: 'cancelorder',
        component: () => import('@/views/order/CancelOrder.vue')
      },
      {
        path: 'review/:id',
        component: () => import('@/views/order/Review.vue')
      },
      {
        path: 'readreview',
        component: () => import('@/views/order/ReadReview.vue')
      }
    ]
  },
  {
    path: '/campaign/',
    component: Blank,
    children: [
      {
        path: 'detail/:id',
        component: () => import('@/views/campaign/CampaignDetail.vue')
      }
    ]
  },
  {
    path: '/profile/',
    component: Blank,
    children: [
      {
        path: 'verify',
        component: () => import('@/views/profile/Verify.vue')
      }
    ]
  },
  {
    path: '/tac/',
    component: Blank,
    children: [
      {
        path: 'tac',
        component: () => import('@/views/tac/TaC.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, _from, next) => {
  if (to.meta.requiresAuth && !window.$cookies.get('accessToken')) {
    return next({ path: '/auth/login' })
  }
  return next()
})


export default router
