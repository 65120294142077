import { toastController } from '@ionic/vue';
import axios from 'axios'

const state = () => ({
  user: {
    alamat: null,
    email: null,
    hp: null,
    idUser: null,
    ktp: null,
    latitude: null,
    longitude: null,
    nama: null,
    pekerjaan: null,
    sKtp: null,
    statVerif: 0,
    tipe: 0,
  },
  maps: {
    initialLoaded: false
  }
})

const getters = {
  getUser: (state: any) => state.user
}

const actions = {
  async fetchUser({ commit }: any) {
    await axios.get("user-detail").then(({ data }) => {
      commit('saveAllUser', data.data)
    }).catch(async ({ response }) => {
      if (response.status == 401) {
        const toast = await toastController.create({
          message: "Sesi telah habis. Silakan login kembali",
          duration: 2000
        })
        window.$cookies.remove('accessToken')
        toast.present()
        setTimeout(() => {
          return location.reload()
        }, 2000);
      }
    });
  },
}

const mutations = {
  saveAllUser(state: any, payload: any) {
    state.user = payload;
  },
  SET_MAPS_LOADED(state: any) {
    state.maps.initialLoaded = true
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
