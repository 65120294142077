import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';
import VueCookies from "vue-cookies";
import axios from "axios";


/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Custom style */
import './theme/color.css';
import './theme/badge.css';
import './theme/button.css';
import './theme/layout.css';
import './theme/typography.css';
import './theme/custom.css';


import store from './store/index'

const app = createApp(App, VueCookies)
  .use(store)
  .use(IonicVue)
  .use(router)

axios.defaults.baseURL = 'https://pastimakan.com/API/'
axios.defaults.headers.common = { 'Authorization': `Bearer ${window.$cookies.get("accessToken")}` }

app.config.globalProperties.$filters = {
  numId(val: any, slice: boolean) {
    let idr = new Intl.NumberFormat('id-ID', {
      style: 'decimal'
    }).format(val)
    if (slice) { idr = idr.slice(0, -3) }
    return idr
  },
  idr(val: any, slice: boolean) {
    let idr = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
    }).format(val)
    if (!slice) { idr = idr.slice(0, -3) }
    return idr
  },
  htmlRemove(val: any) {
    if (val === undefined) { return }
    const strippedString = val.replace(/<\/?[^>]+(>|$)/g, "");
    return strippedString
  }
}

declare global {
  interface Window { $cookies: any }
  interface Window { google: any }
  interface Window { gapi: any }
  interface Window { init: any }

}

window.$cookies = window.$cookies || {};
window.google = window.google || {};
window.gapi = window.gapi || {};
window.init = window.init || {};

app.config.globalProperties.$checkNumberOnly = ($event: any) => {
  const keyCode = ($event.keyCode ? $event.keyCode : $event.which)
  if ((keyCode < 48 || keyCode > 57) && keyCode !== 16 && keyCode !== 17 && keyCode !== 65 && keyCode !== 37 && keyCode !== 39 && keyCode !== 8 && keyCode !== 82 && keyCode !== 116 && keyCode !== 117) {
    $event.preventDefault()
  }
}

router.isReady().then(() => {
  app.mount('#app');
});