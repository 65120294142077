import user from './modules/user'
import order from './modules/order'
import { createStore } from 'vuex'

const store = createStore({
  modules:{
    user: user,
    order: order,
  }
});

export default store
