
const state = () => ({
  menus: [],
  gmaps: false
})

const getters = {
  getMenus: (state: any) => state.menus
}

const actions = {
  
}

const mutations = {
  GMAP_SET_LOADED(state: any) {
    state.gmaps = true
  },
  SET_MENUS(state: any, val: any) {
    const newMenus = [] as any
    val.forEach((e: any) => {
      newMenus.push({
        "gambar": e.gambar,
        "harga": e.harga,
        "id_menu": e.id_menu,
        "ket": e.ket,
        "menu": e.menu,
        "status": e.status,
        "tgl_menu": e.tgl_menu,
        "qty": 0,
        "selected": false
      })
    });
    state.menus = newMenus
  },
  SET_ITEM_MENUS(state: any, val: any) {
    const index = state.menus.findIndex(((obj: any) => obj.id_menu == val.id_menu))
    state.menus[index].qty = val.qty
    state.menus[index].selected = val.selected
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
