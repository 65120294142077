
import { defineComponent } from "vue";
import {
  IonPage,
  IonContent,
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
} from "@ionic/vue";
import { home, cart, flag, person } from "ionicons/icons";
import { mapActions } from "vuex";
import axios from "axios";
export default defineComponent({
  name: "Tabs",

  components: {
    IonPage,
    IonContent,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonLabel,
    IonRouterOutlet,
  },
  methods: {
    ...mapActions('user', {
      fetchUser: 'fetchUser'
    }),
  },
  setup() {
    axios.defaults.headers.common = {'Authorization': `Bearer ${window.$cookies.get("accessToken")}`}
    return { home, cart, flag, person };
  },
  mounted() {
    this.fetchUser()
  }
});
